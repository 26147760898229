import * as React from 'react';
import { WebView } from 'react-native-webview';
import { useRoute} from "@react-navigation/native";
import PDFReader from 'rn-pdf-reader-js'

const Codigoestradadoc = require('../docs/Codigo-estrada-de-Moçambique.pdf')
const sinaisdoc = require('../docs/sinais de transito.pdf')


const PDF_Reader = ({navigation}) => {

    const Route = useRoute();
    
    const item = Route?.params?.item




    return(

        <PDFReader
        source={{
          uri: item?.link,
        }}
      />
    )

}


export default PDF_Reader;

