import React, {useState, useEffect} from 'react';
import { View, Text, SafeAreaView, TouchableOpacity, Dimensions, Image, BackHandler, Alert, RefreshControl, ActivityIndicator} from "react-native";
import {StatusBar} from "expo-status-bar";
import { MaterialIcons,  MaterialCommunityIcons, AntDesign} from '@expo/vector-icons';
import { ProgressBar, Colors } from 'react-native-paper';
import { useRoute, useIsFocused} from "@react-navigation/native";
import CountDown from 'react-native-countdown-component';

import api from "../services/api"


const { height, width} = Dimensions.get("screen")

function QuizScreen({navigation}) {


    const Route = useRoute();
    const IsFocused = useIsFocused();

    const {item, modulo, perguntas} = Route.params;

    const [quiz, setQuiz] = useState([]);
    const [number, setNumber] = useState(0);
    const [pts, setPts] = useState(0);
    const [failpts,setfailpts] =useState(0)
    const [quizprogress, setquizprogress] = useState(0);
    const [ativetest, setativetest] = useState(false);
    const [emfalta, setemfalta] = useState(25)
    const [prova, setProva] = useState([])
    const [ProvaFeita, setProvaFeita] = useState([])

    const [userOption, setuserOption] = useState("");
    const [activecolor, setactivecolor] = useState("#f0f4fd");
    const [descricao, setdescricao] = useState('')

    function Clear(){
        setQuiz([])
        setNumber(0)
        setquizprogress(0)
        setativetest(false)
        setemfalta(25)
        setuserOption("")
        setactivecolor('#f0f4fd')
    }

    const backAction = () => {
        
        
        Alert.alert("Terminar teste?",`Ainda tem ${emfalta} perguntas não respondidas. Deseja realmente terminar o teste?`, [
          {
            text: "Não",
            onPress: () => null,
            style: "cansel "
          },
          { text: "Sim", onPress: () => navigation.goBack() }
        ]);
        return true;
      };


    useEffect(() => {
        
        
        const backHandler = BackHandler.addEventListener(
          "hardwareBackPress",
          backAction
        );
    
        return () => backHandler.remove();

        
      }, [emfalta]);

    function AtivTestF(){

        const perct = (number*100)/25
        const pertual = perct/100
        setquizprogress(pertual)

        if(quiz[number].answer == userOption){
            setPts(pts + 1)
            setactivecolor("rgba(0, 230, 64, 1)")
        }
        else{
            setfailpts(failpts +1)
            //setactivecolor("red")
        }
        
        /* setativetest(true)
        */

        setemfalta(emfalta-1)

        //
        if(number >= 24){

            GravarProva()
            
            setNumber(0)

            setProvaFeita([])
           
            if(pts >= 20){
                
                navigation.navigate('Vitoria', {pts,})
            }
            else{
                navigation.navigate('Derrota', {pts,})
            }
            Clear()
        }

        else NextQuetion()
        
    }

    function NextQuetion(){

        const data = {userOption: userOption, numero:number, questao:quiz[number].id}

        setProvaFeita([...ProvaFeita, data])

        setuserOption("")
        setactivecolor("#f0f4fd")
        
        if(number < (quiz.length-1)){
            setNumber(number +1)
            setativetest(false)
        }
    }

    useEffect(() => {

        if(IsFocused){
            setdescricao(modulo.descricao)

            setQuiz(perguntas.map(item => (
                {
                    question: item.questao,
                    options: shuffle([...item.incorecta_alternativas, item.alternativa_correta]),
                    answer: item.alternativa_correta,
                    image_url:item.imagem_url,
                    id: item._id,
                }
            )));
            
    
        }

    }, [IsFocused]);

    const shuffle = (arr) => arr.sort();

    async function GravarProva(){

        try{

            const response =  await api.post('fazerprovamodular',{ProvaFeita, modulo, pts})

            alert(response.data)

        }catch{err => console.error(err)}
       
        
    }

    if(quiz.length < 1){
        return(
            <View style={{flex:1,backgroundColor:"#fff", justifyContent:"center", alignItems:"center",}}>
                <ActivityIndicator size={'large'}/>
            </View>
        )
    }

    return (

        <SafeAreaView style={{backgroundColor:"#f0f4fd", flex:1, position:"relative"}}>
            <StatusBar backgroundColor="#ffc107" barStyle="light-content" />
            
            <View style={{flexDirection:"row", justifyContent:"space-between", alignItems:"center", 
                elevation:2, backgroundColor:"#fff",paddingHorizontal:width*0.03, height:height*0.07, marginTop:height*0}}>

                <TouchableOpacity onPress={() => backAction()}>
                    <MaterialIcons name="close" size={30} color="#ddd" />
                </TouchableOpacity>
                <ProgressBar progress={quizprogress} color={"#607d8b"} style={{borderRadius:20, height:height*0.01, width:width*0.76}} />
                <MaterialIcons name="flag" size={30} color="#ddd" />
            </View>

            <View style={{ width:width*0.9, alignSelf:"center", marginTop:height*0}}>
                <View style={{ elevation:2,borderRadius:5, width:width*0.9,  backgroundColor:"#fff", alignSelf:"center", marginTop:height*0.02}}>
                    <Image style={{width:width*0.9, height:height*0.21,borderTopLeftRadius:5, borderTopRightRadius:5, resizeMode:"stretch"}} source={{uri:quiz[number].image_url}} />
                    <Text style={{fontWeight:"700", color:"#607d8b", textAlign:"center", marginVertical:height*0.003}}>{`${quiz[number].question}`}</Text>
                    <TouchableOpacity
                        onPress={() => {Alert.alert(
                            'Sobre o tema',
                            `${descricao}`
                        )}}
                        style={{position:'absolute', elevation:5,backgroundColor:'#fff', borderRadius:8}}
                    >
                        <AntDesign name="infocirlceo" size={24} color="#ffa000" />
                    </TouchableOpacity>
                </View>
                

                <View style={{marginTop:height*0.04}}>
                    { quiz[number] &&

                        <>
        
                            <View>
                                {quiz[number].options.map((item, index) => (
                                    <View key={index}>
                                    
                                        {userOption == item ?(
                                            <>
                                                {
                                                    ativetest == false ?(
                                                        <TouchableOpacity onPress={() => setuserOption(item)}
                                                        style={{flexDirection:"row", alignItems:"center",paddingHorizontal:width*0.035, elevation:0,backgroundColor:"#607d8b",
                                                            height:height*0.06, borderRadius:5, marginBottom:height*0.036}}>
                                                        <Text>{item}</Text>
                                                        </TouchableOpacity>
                                                    ):(
                                                        <>
                                                            {userOption == quiz[number].answer?(
                                                                <TouchableOpacity disabled onPress={() => setuserOption(item)}
                                                                    style={{flexDirection:"row",justifyContent:"space-between", alignItems:"center",paddingHorizontal:width*0.035, elevation:0,backgroundColor:"rgba(0, 230, 64, 0.2)",
                                                                        height:height*0.06, borderRadius:5, marginBottom:height*0.036, borderWidth:2, borderColor:"rgba(0, 230, 64, 1)"}}>
                                                                    <Text>{item}</Text>
                                                                    <MaterialIcons name="check" size={24} color="rgba(0, 230, 64, 9)" />
                                                                </TouchableOpacity>
                                                            ):(
                                                                <TouchableOpacity disabled onPress={() => setuserOption(item)}
                                                                    style={{flexDirection:"row",justifyContent:"space-between", alignItems:"center",paddingHorizontal:width*0.035, elevation:0,backgroundColor:"rgba(207, 0, 15, 0.1)",
                                                                        height:height*0.06, borderRadius:5, marginBottom:height*0.036, borderWidth:2, borderColor:"rgba(207, 0, 15, 1)"}}>
                                                                    <Text>{item}</Text>
                                                                </TouchableOpacity>
                                                            )}
                                                        </>
                                                    )
                                                }
                                            </>
                                            
                                        ):(
                                            <>
                                               {ativetest == false ?(
                                                   <TouchableOpacity onPress={() => setuserOption(item)}  style={{flexDirection:"row", alignItems:"center",paddingHorizontal:width*0.035, elevation:2,
                                                        backgroundColor:"#fff", height:height*0.06, borderRadius:5, marginBottom:height*0.036}}>
                                                        <Text>{item}</Text>
                                                    </TouchableOpacity>
                                               ):(
                                                    <>
                                                        {quiz[number].answer == item?(
                                                            <TouchableOpacity disabled onPress={() => setuserOption(item)}  style={{flexDirection:"row", justifyContent:"space-between", alignItems:"center",paddingHorizontal:width*0.035, elevation:2,
                                                            backgroundColor:"#fff", height:height*0.06, borderRadius:5, marginBottom:height*0.036}}>
                                                            <Text>{item}</Text>
                                                            <MaterialIcons name="check" size={24} color="rgba(0, 230, 64, 9)" />
                                                            </TouchableOpacity>
                                                        ):(
                                                            <TouchableOpacity disabled onPress={() => setuserOption(item)}  style={{flexDirection:"row", alignItems:"center",paddingHorizontal:width*0.035, elevation:2,
                                                                backgroundColor:"#fff", height:height*0.06, borderRadius:5, marginBottom:height*0.036}}>
                                                                <Text>{item}</Text>
                                                            </TouchableOpacity>
                                                        )}  
                                                    </>
                                                    
                                               )} 
                                            </>
                                            
                                        )}
                                    
                                    </View>
                                    
                                ))}

                            </View>
                        </>

                    }
                    {
                        number === quiz.length && <>
                        <View>
                            <Text>Fim</Text>
                        </View>
                        </>
                    }
                    
                    

                </View>
            </View>
            

            <View style={{backgroundColor:"#fff",flexDirection:"row", position:"absolute", paddingHorizontal:width*0.05, alignItems:"center",
            justifyContent:"space-between", bottom:0, width:width, height:height*0.07, elevation:4}}>

                
                
                {userOption.trim() == "" ?(
                    <>
                    <TouchableOpacity>
                        <MaterialIcons name="replay-circle-filled" size={30} color="#f0f4fd" />
                    </TouchableOpacity>
                    <TouchableOpacity>
                        <MaterialIcons name="play-circle-fill" size={30} color="#f0f4fd" />
                    </TouchableOpacity>

                    </>
                ):(
                    <>
                        {ativetest == false ?(
                            <>
                            <TouchableOpacity onPress={() => setuserOption("")}>
                                <MaterialIcons name="replay-circle-filled" size={30} color="#ffa000" />
                            </TouchableOpacity>
                            <TouchableOpacity onPress={() => AtivTestF()}>
                                <MaterialIcons name="play-circle-fill" size={30} color="#ffa000" />
                            </TouchableOpacity>
                            </>
                        ):(
                            <>
                            <TouchableOpacity disabled >
                                <MaterialIcons name="replay-circle-filled" size={30} color="#fff" />
                            </TouchableOpacity>
                            <TouchableOpacity onPress={() => NextQuetion()}>
                                <MaterialCommunityIcons name="arrow-right-circle" size={35} color={activecolor} />
                            </TouchableOpacity>
                            </>
                        )}
                    </>
                )}
                
                <View style={{flexDirection:"row", alignItems:"center", position:"absolute", bottom:height*0.06, marginTop:height*0.03}}>
                <ProgressBar progress={1} color={activecolor} style={{backgroundColor:"red",borderRadius:20, height:height*0.01, width:width+10, marginLeft:-5}} />
                
                
            </View>
                
            </View>

            <View style={{position:"absolute", bottom:0, alignSelf:"center", elevation:4}}>
                <CountDown
                    until={60 * 60 + 0}
                    size={12}
                    onFinish={() => alert('Finished')}
                    digitStyle={{backgroundColor: '#f0f4fd'}}
                    digitTxtStyle={{color: 'red'}}
                    timeToShow={['M', 'S']}
                    timeLabels={{m: 'MM', s: 'SS'}}
                />
            </View>
        </SafeAreaView>
    )
}

export default QuizScreen;