import socketio from 'socket.io-client';




const socket = socketio("https://api.passebem.co.mz/",{
    autoConnect:false,
})


function sendData(messages){

    socket.emit('newsms', messages)
    
    /* socket.io.opts.query ={
        messages
    } */

}

export{
    sendData,
    socket
}