import React ,{useState, useEffect, useCallback} from "react";
import {Dimensions, View, TextInput, ActivityIndicator} from "react-native";
import * as ImagePicker from 'expo-image-picker';
import { Bubble, GiftedChat, Send ,Actions, ActionsProps, } from 'react-native-gifted-chat';
import { MaterialCommunityIcons ,FontAwesome } from '@expo/vector-icons';
import { Video, AVPlaybackStatus } from 'expo-av';
import * as DocumentPicker from 'expo-document-picker';
import {sendData, socket} from '../services/socket'
import { useAuth } from "../context/auth";

import api from '../services/api'

const {width, height} = Dimensions.get("screen")

const ChatScreen = ({navigation}) => {

  const {user} = useAuth()

    const [sends, setsends] = useState(false)
    const [messages, setMessages] = useState(null)

    const IMAGEMSEND = async () => {

      

      //let foto = await DocumentPicker.getDocumentAsync({});
      let foto = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.All,
        allowsEditing: true,
        // aspect: [4, 3],
        quality: 1,
      });


      let originalname='chat_image'+'.jpg';
      
      const sendIMG = new FormData;

      sendIMG.append('imagem',{
          uri:foto.uri,
          name:originalname,
          type:"image/jpg",
        });

        sendIMG.append("name",user?.user_inf?.nome||"user",)
        sendIMG.append("avatar",user?.user_inf?.perfil_url||"https://oolhar.com.br/wp-content/uploads/2020/09/perfil-candidatos.jpg",)


        const text = { image: foto.uri ,"user":{"_id":user.id,"name":"TG"}, id:2, received: false, sent: true,}

        setMessages(previousMessages => GiftedChat.append(previousMessages, text))
        
        const response = await api.post('chatimagem',sendIMG)

    }
    
    const VIDIOSEND = async () => {
      let result = await DocumentPicker.getDocumentAsync({});

      let originalname='chat_video'+'.jpg';
      
      const sendVIDEO = new FormData;

      sendVIDEO.append('imagem',{
          uri:result.uri,
          name:originalname,
          type:"video/mp4",
        });

      sendVIDEO.append("name",user?.user_inf?.nome||"user",)
      sendVIDEO.append("avatar",user?.user_inf?.perfil_url||"https://oolhar.com.br/wp-content/uploads/2020/09/perfil-candidatos.jpg",)

        const text = { video: result.uri ,"user":{"_id":user.id,"name":"TG"}, id:2, received: false, sent: false,}

        setMessages(previousMessages => GiftedChat.append(previousMessages, text))

        const response = await api.post('chatvideo',sendVIDEO)

      
	  }

    async function GetMessages(){
      const {data} = await api.get('chatall')

      setMessages(data)
    }

  

    useEffect(() => {
      GetMessages()

      socket.io.opts.query = {
        Authorization:user.id,
      } 
    
      socket.connect()
      
      socket.on('chatall', data => {
        setMessages(data)
      })

      

      
      }, [sends])

      function renderActions(props) {
        return (
          <Actions
            {...props}
            options={{
              ['Enviar Imagem']: () =>  IMAGEMSEND(),
              ['Enviar Video']: () => VIDIOSEND(),
              //['Enviar Audio']: () => alert(1),
            }}
            icon={() => (
              <View style={{width:width*0.06, elevation:6,backgroundColor:"#ffa000", borderRadius:50}}>
                  <MaterialCommunityIcons name="paperclip" size={24} color="#fff" />
              </View>
              
            )}
            onSend={args => onSend(args)}
          />
        )
      }

      const onSend = useCallback((messages = []) => {
        
        setMessages(previousMessages => GiftedChat.append(previousMessages, messages))
      }, [])

      const renderSend = (props) =>{
        return(
          <Send {...props}>
            <View>
              <MaterialCommunityIcons style={{marginBottom:5, marginRight:10}} name="send-circle" size={32} color="#ffa000" />
            </View>
          </Send>
        )
      }
      const RenderBubble = (props) =>{

        return(
          <Bubble
          {...props}
          wrapperStyle={{
            right:{
              backgroundColor:"#ffa000"
            }
          }}
        />
        )
        
      }

      const scrollToBottom =(props) => {

        return(

          <View>
            <FontAwesome name="angle-double-down" size={24} color="black" />
          </View>
        )
      }

      const renderMessageVideo = (props) => {
        const { currentMessage } = props;
        return (
          <View style={{ padding: 20 }}>
             <Video
              resizeMode="contain"
              useNativeControls
              shouldPlay={false}
              source={{ uri: currentMessage.video }}
              style={{height:140, width:250}}
            />
          </View>
        );
      };

      async function EnviarConteudo(received){
       
        const data = {
          text:received[0].text,
          name:user?.user_inf?.nome||"user",
          avatar:user?.user_inf?.perfil_url||"https://oolhar.com.br/wp-content/uploads/2020/09/perfil-candidatos.jpg",

        }
        await api.post('/chattext',data,{
          headers:{
            user:received[0].user._id,
          }
        })
      }
      
    if(!messages){

      return (
        <View style={{flex:1,backgroundColor:"#fff", justifyContent:"center", alignItems:"center",}}>
          <ActivityIndicator size={'large'} />
        </View>
      )
    }

    return(
        <View style={{flex:1}}>
            <GiftedChat
                messages={messages}
                onSend={sms => { EnviarConteudo(sms) }}

                user={{
                    _id: user.id,
                    name:user?.user_inf?.nome||"user",
                    avatar:user?.user_inf?.perfil_url||"https://oolhar.com.br/wp-content/uploads/2020/09/perfil-candidatos.jpg",
                }}
                placeholder='Post, a tua primeira experiência de condução depois de tirar a carta'
                renderMessageVideo={renderMessageVideo}
                renderBubble={RenderBubble}
                alwaysShowSend={true}
                renderSend={renderSend}
                imageStyle={{width:100, height:100}}
                renderActions={renderActions}
                scrollToBottom={true}
                scrollToBottomComponent={scrollToBottom}
                
            />
        </View>
    )
}

export default ChatScreen;